<template>
  <div class="home bg-white">
    <the-app-bar/>
    <home-hero />
    <services />
    <Footer/>
  </div>
</template>

<script>
import Footer from '../../components/footer.vue';
import HomeHero from "../../components/MainView/HomeHero.vue";
import Services from "../../components/MainView/Services.vue";
import TheAppBar from '../../components/NavBar/TheAppBar.vue';
// @ is an alias to /src

export default {
  name: "Home",
  components: { HomeHero, Services, TheAppBar, Footer },
};
</script>
