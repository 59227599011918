<template>
  <div class=" font-montserrat py-20 space-y-10">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-3 text-center">
          <span @click="SelectService('healthhub')">
            <div>
              <img
                src="../../assets/images/Home/Healthhub.jpeg"
                class="
                  w-32
                  h-32
                  object-cover object-center
                  inline-block"
                alt="Product Image"
              />
            </div>
            <h5 class="text-gray-600 font-semibold text-2xl">Healthhub</h5>
          </span>
        </div>
        <div class="col-md-4 text-center mobile">
          <span @click="SelectService('foodhub')">
            <div>
              <img
                src="../../assets/images/Home/FoodHub.jpeg"
                class="
                  w-32
                  h-32
                  object-cover object-center
                  inline-block"
                alt="Product Image"
              />
            </div>
            <h5 class="text-gray-600 font-semibold text-2xl">Foodhub</h5>
          </span>
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid pb-32 px-32 ">
      <div class="row justify-content-center">
        <div class="col-md-4 d-flex justify-content-center">
          <img src="../../assets/images/Home/LPAGE MOBILE PHONE  .png" alt="" />
        </div>
        <div class="col-md-4 ">
          <p
            class="font-montserrat  font-semibold text-black  lg:text-2xl px-5"
          >
            Use the Oonpay #paytag invoice to pay for the following;
          </p>
          <ul class="px-5 justify-center text-lg">
            <li>
              Hospital and medical care bills
            </li>
            <li>Home health visits</li>
            <li>Send pre-paid vouchers</li>
            <li>Medications</li>
            <li>Diagnostic services</li>
            <li class="mb-4">Dental and eye care services</li>
            <div class="row ">
              <div class="col-md-12 flex justify-center">
                <a href="#">
                  <img
                    src="../../assets/icons/googlestore.svg"
                    alt="Available on Google Store"
                    width="65%"
                  />
                </a>
                <a href="#">
                  <img
                    class="center"
                    src="../../assets/icons/applestore.svg"
                    alt="Available on Apple Store"
                    width="65%"
                  />
                </a>
              </div>
            </div>
            <div class="row mt-8">
              <div class="col-md-12">
                <router-link
                  to="/service"
                  class="rounded-full btn-mobile oonpay-btn px-10 py-2 mt-10"
                >
                  Lear More
                </router-link>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div> -->

    <div class="container ">
      <div class="row oonpay-column  ">
        <div class="col-md-12 ">
          <h1 class="px-5 font-bold text-oonpaysecondary">HEALTHHUB</h1>
          <p class="px-5 lg:text-2xl font-semibold text-black">
            Pay health care bills for friends and family members
          </p>
        </div>
        <div class="cont pl-5">
          <div class="flex mb-4">
            <button
              @click="activeTab = 'tab1'"
              :class="{
                'bg-blue-500 text-white': activeTab === 'tab1',
                'bg-gray-200': activeTab !== 'tab1'
              }"
              class="px-4 py-2 mr-2 rounded-md"
            >
              Pre-Paid Vouchers
            </button>
            <button
              @click="activeTab = 'tab2'"
              :class="{
                'bg-blue-500 text-white': activeTab === 'tab2',
                'bg-gray-200': activeTab !== 'tab2'
              }"
              class="px-4 py-2 mr-2 rounded-md"
            >
              #Paytag
            </button>
            <button
              @click="activeTab = 'tab3'"
              :class="{
                'bg-blue-500 text-white': activeTab === 'tab3',
                'bg-gray-200': activeTab !== 'tab3'
              }"
              class="px-4 py-2 rounded-md"
            >
              QR-Code Payments
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="activeTab === 'tab1'">
      <div class="container">
        <div class="row oonpay-row justify-content-center">
          <div class="col-md-6 oonpay-column">
            <h1 class="px-5 font-bold text-oonpaysecondary">
              Pre-Paid Vouchers
            </h1>

            <p
              class="font-montserrat  font-semibold text-black  lg:text-2xl px-5"
            >
              Use the Oonpay pre-paid vouchers to send healthcare pack to
              friends and family in Africa and beyond.
            </p>
            <ul class="px-5 justify-center text-lg">
              <li>
                Wellness and preventive check vouchers
              </li>
              <li>Physiotherapy session vouchers</li>
              <li>Pre-natal care vouchers</li>
              <li>Dental and eye check vouchers</li>
              <li>Diagnostic service vouchers</li>
              <li>Covid vaccine vouchers</li>
              <li class="mb-4">Home lab service vouchers</li>

              <button
                @click="SelectService('healthhub')"
                class="rounded btn-mobile oonpay-btn px-10 py-2 mt-6"
              >
                Send a healthcare voucher Now
              </button>
            </ul>
          </div>
          <div class="col-md-6 image-container">
            <img
              src="../../assets/images/Home/pre paid voucher   image .png"
              alt=""
            />
            <button class="play-button" @click="openVideoPopup()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="50"
                height="50"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        </div>

        <!-- Video Popup -->
        <div class="video-popup" id="videoPopup">
          <div class="video-wrapper">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/fel7fRpeW8M"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <button class="close-button" @click="closeVideoPopup()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="activeTab === 'tab2'">
      <div class="container">
        <div class="row oonpay-row justify-content-center">
          <div class="col-md-6 oonpay-column">
            <h1 class="px-5 font-bold text-oonpaysecondary">
              #paytag
            </h1>

            <p
              class="font-montserrat  font-semibold text-black  lg:text-2xl px-5"
            >
              Use the Oonpay #paytag invoice to pay for the following.
            </p>
            <ul class="px-5 justify-center text-lg">
              <li>
                Hospital and medical care bills
              </li>
              <li>Home health visits</li>
              <li>Medications</li>
              <li>Diagnostic services</li>
              <li>Diagnostic service vouchers</li>
              <li class="mb-4">Dental and eye care services</li>

              <button
                @click="SelectService('healthhub')"
                class="rounded btn-mobile oonpay-btn px-10 py-2 mt-6"
              >
                Send a healthcare voucher Now
              </button>
            </ul>
          </div>
          <div class="col-md-6 image-container">
            <img
              src="../../assets/images/Home/LPAGE MOBILE PHONE  .png"
              alt=""
            />
            <button class="play-button" @click="openVideoPopup()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="50"
                height="50"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        </div>

        <!-- Video Popup -->
        <div class="video-popup" id="videoPopup">
          <div class="video-wrapper">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ErMSHiQRnc8"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <button class="close-button" @click="closeVideoPopup()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="activeTab === 'tab3'">
      <div class="container">
        <div class="row oonpay-row justify-content-center">
          <div class="col-md-6 oonpay-column">
            <h1 class="px-5 font-bold text-oonpaysecondary">
              QR-Code Instant Payment
            </h1>

            <p
              class="font-montserrat  font-semibold text-black  lg:text-xl px-5"
            >
              Use the Oonpay QR-code instant, easy and secure payment method to
              process on-site bills and enjoy touchless payments.
            </p>
            <ul class="px-5 justify-center text-lg">
              <li>
                Hospital admission fees
              </li>
              <li>Emergency ambulatory service</li>
              <li>Urgent medical needs</li>
              <li class="mb-4">Medications</li>

              <button
                @click="SelectService('healthhub')"
                class="rounded btn-mobile oonpay-btn px-10 py-2 mt-6"
              >
                Send a healthcare voucher Now
              </button>
            </ul>
          </div>
          <div class="col-md-6 image-container">
            <img
              src="../../assets/images/Home/QR CODE  PAYMENT    image .png"
              alt=""
            />
            <button class="play-button" @click="openVideoPopup()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="50"
                height="50"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        </div>

        <!-- Video Popup -->
        <div class="video-popup" id="videoPopup">
          <div class="video-wrapper">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ErMSHiQRnc8"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <button class="close-button" @click="closeVideoPopup()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container ">
      <div class="row oonpay-column  ">
        <div class="col-md-12 ">
          <h1 class="px-5 font-bold text-oonpaysecondary">FOODHUB</h1>
          <p class="px-5 lg:text-2xl font-semibold text-black">
            Send food vouchers to friends and family members.
          </p>
        </div>
        <button class="px-4 py-2 mr-2 rounded-md bg-blue-500 text-white ml-5">
          Pre-Paid Food Vouchers
        </button>
      </div>
    </div>

    <div class="container pb-5">
      <div class="row justify-content-center">
        <div class="col-md-6 image-container">
          <img src="../../assets/images/food.png" alt="" />

          <button class="play-button" @click="openVideoPopup()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="50"
              height="50"
            >
              <path d="M8 5v14l11-7z" />
            </svg>
          </button>
        </div>
        <div class="col-md-6 ">
          <h1 class="px-5 font-bold text-oonpaysecondary">
            Pre-Paid Food Vouchers
          </h1>
          <p class="font-montserrat  font-semibold text-black  lg:text-xl px-5">
            With the oonpay food vouchers, wholesale food items such as bags of
            rice, beans, tubers of yam, etc. can be purchased for friends and
            relatives from anywhere in the world.
          </p>
          <ul class="px-5 justify-center text-lg">
            <li>
              Wholefood Vouchers
            </li>
            <li>Specialty Food Vouchers</li>
            <li>Drinks & Beverages Vouchers</li>
            <li>Frozen Food Vouchers</li>
            <li>Cooked Meals Vouchers</li>
            <li>Snacks Vouchers</li>
            <li>Diabetic Meal Vouchers</li>
            <li>Whole Grain Vouchers</li>
            <li>Dietary Supplements Vouchers</li>
            <li>Fast FoodVouchers</li>
            <li>Local Delicacy Vouchers</li>
            <li>Livestock Vouchers</li>
            <li>Party Meals Vouchers</li>
            <li class="mb-4">Baby Food Vouchers</li>
            <button
              @click="SelectService('foodhub')"
              class="rounded-full btn-mobile oonpay-btn px-10 py-2 mt-6"
            >
              Send a food voucher now
            </button>
          </ul>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row oonpay-row justify-content-center items-center">
        <div class="col-md-6 oonpay-column">
          <h1 class="px-5 font-bold text-oonpaysecondary">
            Oonpay Mobile App
          </h1>

          <p
            class="font-montserrat  font-semibold text-black  lg:text-2xl px-5"
          >
            Download the oonpay app today
          </p>

          <img src="../../assets/images/Home/icon.png" alt="" />
        </div>
        <div class="col-md-6 ">
          <img src="../../assets/images/Home/phone.png" alt="" />
        </div>
      </div>
    </div>

    <div class="container-fluid pt-10 px-32">
      <div class="row">
        <div class="col-md-12">
          <h2 class="font-bold text-black text-center">
            Oonpay Fulfilments Locations
          </h2>
        </div>
      </div>
      <div class="row oonpay-row justify-content-center items-center">
        <div class="col-md-6 oonpay-column">
          <img src="../../assets/images/Home/africamap.png" alt="" />
        </div>
        <div class="col-md-6 ">
          <p
            class="font-montserrat  font-semibold text-black mb-5 lg:text-xl px-5"
          >
            Our Oonpay local services providers are now avaliable in the
            following countries
          </p>

          <router-link
            to="/our-locations"
            class="rounded-full oonpay-btn px-10 py-3 mx-5"
          >
            View Details
          </router-link>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row oonpay-row justify-content-center items-center">
        <div class="col-md-6 oonpay-column">
          <h2 class="font-bold text-oonpaysecondary">
            Become An Oonpay Network Partner
          </h2>
          <h4 class=" font-semibold text-black">
            Start a local business in your community with a global reach with
            the following oppourtunities and benefits from the Oonpay Platform
          </h4>

          <p class="font-montserrat  text-black  lg:text-xl">
            We provide your brand with global visibility and unrestricted access
            to the international market.
          </p>

          <p class="font-montserrat  text-black  lg:text-xl">
            You experience speedy payment remittance time for both goods and
            services.
          </p>

          <p class="font-montserrat  text-black  lg:text-xl mb-5">
            Experience significant growth and improvement for your business
            revenue and personal income.
          </p>

          <router-link
            to="/join-network"
            class="rounded-full oonpay-btn px-10 py-3"
          >
            Join Us Now
          </router-link>
        </div>
        <div class="col-md-6 ">
          <img src="../../assets/images/Home/ert22.png" alt="" />
        </div>
      </div>
    </div>

    <div class="row flex justify-content-center">
      <div class="col-md-3 oonpay-middle ">
        <img
          src="../../assets/images/Home/A1 .png"
          class="p-2"
          width="400px"
          alt=""
        />
        <p
          class="font-montserrat  font-semibold text-black text-center lg:text-lg"
        >
          Business & Health Care Providers
        </p>
        <p class="text-center  px-3">
          Health care providers get paid remotely, from anywhere in the world
        </p>
        <div class="col-md-12 text-center">
          <button class="rounded-full  oonpay-btn px-10 py-2">
            Learn More
          </button>
        </div>
      </div>
      <div class="col-md-3 oonpay-middle ">
        <img
          src="../../assets/images/Home/A2.png"
          class="p-2"
          width="400px"
          alt=""
        />
        <p
          class="font-montserrat  font-semibold text-black text-center lg:text-xl"
        >
          For You, Family and Friends
        </p>
        <p class="text-center px-3">
          Download the Oonpay app on google play store and Apple IOS
        </p>

        <div class="col-md-12 text-center ">
          <button class="rounded-full oonpay-btn px-10 py-2">
            Learn More
          </button>
        </div>
      </div>
      <div class="col-md-3 oonpay-middle">
        <img
          src="../../assets/images/Home/A3.png"
          class="p-2"
          width="400px"
          alt=""
        />
        <p
          class="font-montserrat  font-semibold text-black text-center lg:text-xl"
        >
          Partner With Us
        </p>
        <p class="text-center px-3">
          Join our global Network From your comfort of your local practice.
        </p>

        <div class="col-md-12 text-center ">
          <router-link
            to="/service"
            class="rounded-full oonpay-btn px-10 py-2 mt-10"
          >
            Learn More
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ConfirmToken from "./ConfirmToken.vue";
export default {
  // components: { ConfirmToken },

  data() {
    return {
      activeTab: "tab1"
    };
  },

  methods: {
    // to="/health-supercategories"
    SelectService(service) {
      // console.log(service);
      localStorage.setItem("service", service);
      if (service == "healthhub") {
        this.$router.push("/health-supercategories");
      } else {
        this.$router.push("/foodhub");
      }
    },

    openVideoPopup() {
      document.getElementById("videoPopup").style.display = "block";
    },

    closeVideoPopup() {
      document.getElementById("videoPopup").style.display = "none";
    }
  }
};
</script>

<style scoped>
.rol {
  display: flex;
  justify-content: space-between;
  /* Optional: Adjust container styles as needed */
}

.column {
  flex: 1 1 1; /* Adjust the width as needed */
  margin-bottom: 20px; /* Optional: Add spacing between columns */
  margin-right: 15px;
}

.oonpay-btn {
  background-color: #e87c34;
  color: #fff;
}

img.center {
  display: block;
  margin: 0 auto;
}

.bg-warning {
  background: #fdf5ea !important;
}

.image-container {
  position: relative;
  display: inline-block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px; /* Adjust button size */
  height: 50px; /* Adjust button size */
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  /* Additional styles for the button appearance */
}

.play-button svg path {
  fill: #e87c34; /* Change to your desired color */
}

.video-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.video-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .mobile {
    text-align: center;
    margin-top: 25px;
  }
  .oonpay-padding {
    margin-top: 35px;
  }

  .oonpay-row {
    flex-direction: column-reverse;
  }
  .oonpay-column {
    margin-top: 30px;
  }
  .oonpay-middle {
    margin: 30px;
  }

  .btn-mobile {
    display: flex;
    justify-content: center;
    color: #fff;
  }
}
</style>
