<template>
  <div class="py-32 mt-5 h-80 flex bg-img flex-col justify-center items-center">
    <div class="logo">
      <img src="../../assets/icons/Oonpay logo.png" alt="" />
    </div>
    <h3
      class="font-montserrat font-black text-oonpaysecondary  text-center lg:text-4xl"
    >
      Global Direct Payment Network
    </h3>
    <p class="font-montserrat   mt-2 text-center text-sm">
      Connecting, Uplifting, And Supporting Family And Friends.
    </p>
    <p class=" font-montserrat font-black text-white text-center lg:text-4xl">
      Global Direct Payment Network
    </p>
    <div class="input-wrapper">
      <div class="fa fa-search"></div>
      <input
        type="text"
        id="searchTerm"
        v-on:keyup.enter="onEnter"
        v-model="searchTerm"
        placeholder="Search For #paytag, #fundtag, Invoice"
      />
    </div>
    <Modal @close="toggleModal" :modalActive="modalActive">
      <div class="modal-content">
        
        <p class="text-center text-danger">{{errorMessage}}</p>
      </div>
    </Modal>
   
    <!-- <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader> -->
    <!-- <ring-loader :loading="loading" :color="color" :size="size"></ring-loader> -->
    <bounce-loader :loading="loading" :color="color"></bounce-loader>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ Modaltitle }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
           
            <div v-if="ShowForm == 1">
              <h4 class="text-center">
                Invoice is available and is due on {{ expDate | formatDate() }}
              </h4>
              <h3 class="text-center bob text-oonpaysecondary">
                Do you want to make payment ?
              </h3>
            </div>
            <div v-if="ShowForm == 2">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <select name="" v-model="callcode" id="" class="form-control">
                    <option value="" selected> Select Country Code</option>
                    <option
                      v-for="code in countrycodes"
                      :key="code.index"
                      :value="code.callCode"
                      >{{ code.callCode }}</option
                    >
                  </select>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="phonenumber"
                  placeholder="Enter Phone Number"
                />
              </div>
            </div>
            <input
              v-if="ShowForm == 3"
              type="text"
              class="form-control"
              v-model="OTP"
              placeholder="Enter OTP"
            />
            <span class="text-danger p-1" v-if="errors">{{errors.data}}</span>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-secondary"
              v-if="ShowForm == 1"
            >
              No
            </button>
            <button
              type="button"
              @click.prevent="showNextForm"
              class="btn btn-primary"
              v-if="ShowForm == 1"
            >
              Yes
            </button>
            <button
              v-if="ShowForm == 3"
              type="button"
              @click.prevent="ResendOTP"
              class="btn btn-sm btn-info"
            >
            Resend OTP
            </button>
            <button
              v-if="ShowForm == 2 || ShowForm == 3"
              type="button"
              @click.prevent="ProcessVerification"
              class="btn btn-sm btn-primary"
            >
              {{ ShowForm == 2 ? "Send OTP" : "Verify OTP" }} 
            </button>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";
import { mapGetters } from 'vuex';
import Modal from '../Global/Modal.vue';



export default {
  components: {
    BounceLoader,
    Modal,
  },
  data() {
    return {
      color: "red",
      loading: false,
      callcode: "",
      phonenumber: "",
      searchTerm: "",
      Modaltitle: "Verify  Number",
      ShowForm: 0,
      searchResult: true,
      countrycodes: [],
      OTP: "",
      expDate: "",
      modalActive:false,
      errorMessage:"",
    };
  },
  computed:{
    ...mapGetters("invoice",["errors"])
   
  },
  mounted() {
    this.$store.dispatch("dashboard/GetAllCountries").then((response) => {
      this.countrycodes = response;
    });
  },
  methods: {
    onEnter() {
      this.loading = true;
      this.$store
        .dispatch("invoice/searchClientInvoice", this.searchTerm)
        .then((response) => {
          if (response.data) {
            this.loading = false;
            this.expDate = response.data;
            this.Modaltitle = "Invoice";
            this.ShowForm = 1;

            $("#exampleModal").modal("show");
          }
        })
        .catch((err) => {
          this.modalActive = true
          this.errorMessage = err.response.data
         
          this.loading = false;
        });
    },

    toggleModal(){
      this.modalActive = false
    },

    showNextForm() {
      this.ShowForm = 2;
      this.Modaltitle = "Verify Your Number";
    },

     ProcessVerification() {
      let newPhone = this.phonenumber;
      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var client_phone = newPhone.substring(1);
      }else{
        client_phone = this.phonenumber;
      }
      var main_number = `${this.callcode}${client_phone}`;
      const data = {
        phonenumber: main_number,
        code: this.callcode,
        otp: this.OTP,
        searchTerm: this.searchTerm,
      };

      if (this.ShowForm == 2) {
         this.$store.dispatch(
          "healthhub/VerifyAdminPhone",
          data
        ).then(response=>{
          if (response.data == true) {
          this.Modaltitle = "Enter Verification Code";
          this.ShowForm = 3;
        }
        })
        
      } else if (this.ShowForm == 3) {
      this.$store.dispatch("invoice/getSearchedInvoiceDetails", data)
          
          // .then((response) => {
          //   console.log(response)
          //   if (response.status == 200) {
          //     localStorage.setItem("invoice", JSON.stringify(response.data));
          //     $("#exampleModal").modal("hide");
          //     this.$router.push("/invoice-search-details");
          //   }
          // })
          // .catch((e) => {
          //   console.log("err",e.response.data)
          //   this.$toasted.error(
          //     "You do not have access to this invoice. Ask invoice owner to add you as benefactor.",
          //     {
          //       duration: 5000,
          //     }
          //   );
          // });

        //  await $("#exampleModal").modal("hide");
        //    this.$router.push("/invoice-search-details")
      }
    },

    ResendOTP(){
      let newPhone = this.phonenumber;
      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var client_phone = newPhone.substring(1);
      }
      var main_number = `${this.callcode}${client_phone}`;
      const data = {
        phonenumber: main_number,
        code: this.callcode,
        otp: this.OTP,
        searchTerm: this.searchTerm,
      };

      this.$store.dispatch(
          "healthhub/VerifyAdminPhone",
          data
        )
    }
  },
};
</script>



<style scoped>
.logo,
p {
  font-size: 1.5rem;
}

.bob, h4{
  font-family: "Josefin Sans", sans-serif;
}

.input-wrapper {
  display: flex;
  width: 1000px;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  border: 2px soild #dddd;
  font-size: 1.5rem;
  margin: 15px 15px;
  -webkit-box-shadow: 2px 3px 8px #dddd;
  -moz-box-shadow: 2px 3px 8px #dddd;
  box-shadow: 2px 3px 8px #dddd;
}

#searchTerm {
  margin: 0 0.5rem 0 0.5rem;
  width: 100%;
  border: none;
  outline: none;
  color: #000;
}

::placeholder {
  color: #dddddd;
}

.fa-search {
  color: black;
}

@media (max-width: 640px) {
  .input-wrapper {
    display: flex;
    width: 330px;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: white;
    border: 2px soild #dddd;
    font-size: 1rem;
    margin: 15px 15px;
    -webkit-box-shadow: 2px 3px 8px #dddd;
    -moz-box-shadow: 2px 3px 8px #dddd;
    box-shadow: 2px 3px 8px #dddd;
  }
}
</style>
